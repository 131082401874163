html,
body,
#root,
#root>div {
  height: 100%;
}

.material-icons {
  position: relative;
  top: 4px;
}

** Channel List **
.channel-list a {
  color: white;
}


/*** Header ***/
header img {
  width: 40px;
  border-radius: 50%;
}

header button.user-icon {
  padding: .25rem;
}

.highlighted {
  border: 3px solid greenyellow;
}

.bg-primary .nav-link {
  color: white;
}

.bg-primary .nav-pills .nav-link.active {
  color: #0d6efd;
  background-color: white;
}

header .nav-item {
  display: flex;
  align-items: center;
}

/*** Modal ***/
.custom-modal-style {
  width: 300%;
}

/*** Profile ***/
.image-upload-form img {
  display: block;
  width: 250px;
  height: 250px;
}

.lead img {
  width: 100px;
}

.dropDownImg {
  width: 100px;
}

.comcodeTable {
  text-align: center;
}

table {
  width: 100%;
  table-layout: fixed;
}

.centered {
  text-align: center;
}

.collapsibleTable {
  margin: auto;
  width: 95%;
}

.comcodeNotesModal {
  text-align: center;
}

.locationLKSIEmailModal {
  text-align: center;
}

.locationLKSINotesModal {
  text-align: center;
}

.fixedNoWrap {
  overflow: hidden;
  white-space: nowrap;
  width: 20px;  /* or whatever */
}

.changedField {
 font-weight: 800;
}
td {
  text-align: center;
  vertical-align: middle;
}
th {
  text-align: center;
  vertical-align: middle;

}

.centered {
  text-align: center;
  vertical-align: middle;

}
.custom-control-label::before, 
.custom-control-label::after {
  width: 2.25rem ;
  height: 2.25rem ;
  margin: 1rem;
}
.custom-control-label{
  margin: 1.5rem 2rem;
}

.seasonCardRow {
  padding-top: 1%
}

.count {
  color: #0d6efd;
  font-size: 1.5rem
}